
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GhButton',
  props: {
    size: {
      type: String,
      default: 'middle'
    },
    type: {
      type: String,
      default: 'default'
    },
    color: {
      type: String,
      default: '#161617'
    }
  }
})


import { defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import * as types from '@/store/modules/common/mutation-type'
import apolloClient from '@/apollo-client'
import { queryApi } from '@/graphql/query'
import * as storage from '@/utils/storage'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const route = useRoute()
    const store = useStore()
    const isShowView = ref(false)
    const result = ref()
    // 组装缓存表用户数据
    const formatInfo = () => {
      let landInfo = {}
      let othersInfo = {}
      let common = {}
      Object.keys(result.value).forEach(item => {
        if (result.value[item].data.meta.startsWith('land')) {
          const content = JSON.parse(result.value[item].data.content)
          landInfo = { ...landInfo, ...content }
        } else if (result.value[item].data.meta.startsWith('others')) {
          const content = JSON.parse(result.value[item].data.content)
          othersInfo = { ...othersInfo, ...content }
        } else {
          const content = JSON.parse(result.value[item].data.content)
          common = content
        }
      })
      store.commit(types.OPEN_INFO_LAND, { info: landInfo })
      store.commit(types.OPEN_INFO_OTHERS, { info: othersInfo })
      store.commit(types.OPEN_COMMON, { info: common })
      isShowView.value = true
    }

    onMounted(async () => {
      if (route.path === '/open-account/home') {
        isShowView.value = true
        return
      }
      const queryFunMap = {
        openAccount: queryApi.clientFormDraftAll,
        openImmigrantAccount: queryApi.clientFormDraftAllByOpenImmigrantAccount
      } as any
      const formType = storage.get('formType') as string
      const res = await apolloClient.query({
        query: queryFunMap[formType] || queryFunMap.openAccount,
        fetchPolicy: 'no-cache',
        variables: {
          kindSource: 1
        }
      })
      result.value = res?.data
      // console.log(route.query)
      formatInfo()
    })

    watch(() => route.query, (val) => {
      if (val?.type) {
        storage.set('formType', val.type)
      }
    }, { deep: true, immediate: true })

    return {
      isShowView
    }
  }
})

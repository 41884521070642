import gql from 'graphql-tag'

export const queryApi: any = {
  // 银行卡ocr识别（只支持大陆银行卡
  bankCardOcr: gql`
    query bankCardOcr($params: CardOrcInput!) {
      bankCardOcr(param: $params) {
        data {
          cardNo
          bankInfo
          validDate
          cardType
          cardName
          requestId
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 银行卡四要素校验
  bankCardVerify: gql`
    query bankCardVerify($params: BankCard4VerifyInput!) {
      bankCardVerify(param: $params) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 查询缓存表数据
  clientFormDraft: gql`
    query clientFormDraft($filter: FilterClientFormDraftInput!) {
      clientFormDraft(filter: $filter) {
        data {
          content
        }
        ok
        reason {
          desc
        }
      }
    }
  `,
  // 查询普通开户缓存表数据
  clientFormDraftAll: gql`
    {
      open_common: clientFormDraft(filter: { formType: openAccount, meta: "open_common" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_1: clientFormDraft(filter: { formType: openAccount, meta: "land_1" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_2: clientFormDraft(filter: { formType: openAccount, meta: "land_2" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_3: clientFormDraft(filter: { formType: openAccount, meta: "land_3" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_4: clientFormDraft(filter: { formType: openAccount, meta: "land_4" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_5: clientFormDraft(filter: { formType: openAccount, meta: "land_5" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_6: clientFormDraft(filter: { formType: openAccount, meta: "land_6" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_7: clientFormDraft(filter: { formType: openAccount, meta: "land_7" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_8: clientFormDraft(filter: { formType: openAccount, meta: "land_8" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_9: clientFormDraft(filter: { formType: openAccount, meta: "land_9" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_10: clientFormDraft(filter: { formType: openAccount, meta: "land_10" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_11: clientFormDraft(filter: { formType: openAccount, meta: "land_11" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_12: clientFormDraft(filter: { formType: openAccount, meta: "land_12" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_13: clientFormDraft(filter: { formType: openAccount, meta: "land_13" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_14: clientFormDraft(filter: { formType: openAccount, meta: "land_14" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_15: clientFormDraft(filter: { formType: openAccount, meta: "land_15" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_16: clientFormDraft(filter: { formType: openAccount, meta: "land_16" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_17: clientFormDraft(filter: { formType: openAccount, meta: "land_17" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_1: clientFormDraft(filter: { formType: openAccount, meta: "others_1" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_2: clientFormDraft(filter: { formType: openAccount, meta: "others_2" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_3: clientFormDraft(filter: { formType: openAccount, meta: "others_3" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_4: clientFormDraft(filter: { formType: openAccount, meta: "others_4" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_5: clientFormDraft(filter: { formType: openAccount, meta: "others_5" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_6: clientFormDraft(filter: { formType: openAccount, meta: "others_6" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_7: clientFormDraft(filter: { formType: openAccount, meta: "others_7" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_8: clientFormDraft(filter: { formType: openAccount, meta: "others_8" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_9: clientFormDraft(filter: { formType: openAccount, meta: "others_9" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_10: clientFormDraft(filter: { formType: openAccount, meta: "others_10" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_11: clientFormDraft(filter: { formType: openAccount, meta: "others_11" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_12: clientFormDraft(filter: { formType: openAccount, meta: "others_12" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_13: clientFormDraft(filter: { formType: openAccount, meta: "others_13" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_14: clientFormDraft(filter: { formType: openAccount, meta: "others_14" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_15: clientFormDraft(filter: { formType: openAccount, meta: "others_15" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_16: clientFormDraft(filter: { formType: openAccount, meta: "others_16" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_17: clientFormDraft(filter: { formType: openAccount, meta: "others_17" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_18: clientFormDraft(filter: { formType: openAccount, meta: "others_18" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
    }
  `,
  // 查询投资移民缓存表数据
  clientFormDraftAllByOpenImmigrantAccount: gql`
    {
      open_common: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "open_common" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_1: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_1" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_2: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_2" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_3: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_3" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_4: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_4" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_5: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_5" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_6: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_6" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_7: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_7" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_8: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_8" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_9: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_9" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_10: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_10" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_11: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_11" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_12: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_12" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_13: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_13" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_14: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_14" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_15: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_15" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_16: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_16" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_17: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_17" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      land_18: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "land_18" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_1: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_1" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_2: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_2" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_3: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_3" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_4: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_4" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_5: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_5" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_6: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_6" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_7: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_7" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_8: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_8" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_9: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_9" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_10: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_10" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_11: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_11" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_12: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_12" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_13: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_13" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_14: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_14" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_15: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_15" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_16: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_16" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_17: clientFormDraft(filter: { formType: openImmigrantAccount , meta: "others_17" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      others_18: clientFormDraft(filter: { formType: openImmigrantAccount, meta: "others_18" }) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
    }
  `,
  //  查询客户是否黑名单
  customerBlackQuery: gql`
    query customerBlackQuery($params: CustomerBlackQueryInput!) {
      customerBlackQuery(param: $params) {
        data {
          blackFlag
          isOpenIdCard
          isExistedIdCard
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 验证邮箱唯一性
  customerEmailCheck: gql`
    query customerEmailCheck($params: CustomerEmailCheckInput!) {
      customerEmailCheck(param: $params) {
        data {
          isExistedEmail
          isOpenEmail
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   用户开户状态查询
  customerOpenStatus: gql`
    query customerOpenStatus($params: CustomerOpenStatusInput) {
      customerOpenStatus(param: $params) {
        data {
          applicationStatus
          statusDes
          openResult
          rejectContent
          rejectContentOther
          rejectFiles {
            id
            applicationId
            businessType
            imageType
            fileName
            fileType
            fileStorageName
            fileExtName
            filePath
            remoteUrl
            createUser
            updateUser
          }
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   查询开户图片缓存
  fileTempQuery: gql`
    query fileTempQuery($params: FileTempQueryInput!) {
      fileTempQuery(param: $params) {
        data {
          id
          userId
          applicationId
          idKindType
          imageType
          fileName
          fileType
          fileStorageName
          fileExtName
          filePath
          remoteUrl
          source
          businessType
          createTime
          updateTime
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   查找字典（支持批量查询）
  findDictOpenAccount: gql`
    query findDictOpenAccount($params: FindDictInput) {
      findDictOpenAccount(param: $params) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   香港身份证OCR
  hkIdCardOcr: gql`
    query hkIdCardOcr($params: HkCardOrcInput!) {
      hkIdCardOcr(param: $params) {
        data {
          cnName
          enName
          telexCode
          sex
          birthday
          permanent
          idNum
          symbol
          firstIssueDate
          currentIssueDate
          fakeDetectResult
          warningCode
          requestId
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   OCR识别
  mainlandIdCardOcr: gql`
    query mainlandIdCardOcr($params: CardOrcInput!) {
      mainlandIdCardOcr(param: $params) {
        data {
          name
          sex
          nation
          birthday
          address
          idNum
          authority
          validDate
          advancedInfo
          requestId
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   中国大陆护照ocr识别
  mainlandPassportOcr: gql`
    query mainlandPassportOcr($params: CardOrcInput!) {
      mainlandPassportOcr(param: $params) {
        data {
          passportNo
          sex
          nationality
          country
          birthDate
          birthPlace
          issueDate
          issuePlace
          expiryDate
          signature
          codeSet
          codeCrc
          name
          familyName
          firstName
          requestId
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   提交开户资料
  openInfoSave: gql`
    query openInfoSave($params: OpenInfoSaveInput!) {
      openInfoSave(param: $params) {
        data
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  //   港澳台及境外护照ocr识别
  overseasPassportOcr: gql`
    query overseasPassportOcr($params: CardOrcInput!) {
      overseasPassportOcr(param: $params) {
        data {
          passportNo
          name
          birthDate
          sex
          expiryDate
          country
          nationality
          requestId
        }
        ok
        reason {
          err
          desc
        }
      }
    }
  `,
  // 获取草稿进度
  allFormTypeDrafts: gql`
    query allFormTypeDrafts($filter: FilterAllFormTypeDraftsInput!) {
      allFormTypeDrafts(filter: $filter) {
        ok
        reason {
          err
          desc
        }
        data {
          formType
          meta
          content
        }
      }
      
    }
  `
}

export const checkIpLocation = gql`
query checkIpLocation {
  checkIpLocation {
    data
    ok
    reason {
      err
      desc
    }
  }
}
`

export const queryTransferPro = gql`
  query queryTransferPro ($paramsDic: FindDictInput, $params: CustomerOpenStatusInput) {
    findDictOpenAccount: findDictOpenAccount(param: $paramsDic) {
      data
      ok
      reason {
        err
        desc
      }
    },
    customerOpenStatus: customerOpenStatus(param: $params) {
      data {
        applicationStatus
      }
      ok
      reason {
        err
        desc
      }
    } 
  }
`

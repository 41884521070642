
import { onMounted, ref } from 'vue'
export default {
  name: 'CustomDialog',
  props: {
    title: {
      type: String,
      default: '开户提醒'
    },
    content: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: '我知道了，继续开户'
    },
    onConfirm: {
      type: Function
    },
    onCancel: {
      type: Function
    }
  },
  setup (props: any) {
    const fade = ref(false)
    // 组件渲染完毕后
    onMounted(() => {
      setTimeout(() => {
        fade.value = true
      }, 0)
    })
    // 取消
    const cancel = () => {
      props.onCancel()
    }
    // 确认
    const submit = () => {
      props.onConfirm()
    }
    return { cancel, submit, fade }
  }
}

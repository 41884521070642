import UserAgent from '@/utils/useragent'
import * as storage from '@/utils/storage'

interface OpenView {
  fileType: string
  fileUrl: string
  title: string
}

// 判断是否在APP内
export const isInAppWebview = () => {
  try {
    window.jockey.invoke('isInAppWebview', {}, () => {
      console.log(1)
    })
    return true
  } catch (error) {
    return false
  }
}

/**
 * APP获取用户信息
 * @returns
 */
export const getUserInfo = () => new Promise((resolve) => {
  window.jockey.invoke('getUserInfo', {}, (result: any) => {
    storage.set('userInfo', result.data)
    return resolve(result.data)
  })
})

/**
 *
 * @param type camera picture
 * @returns
 */
export const getPictureAction = (type: string) => new Promise((resolve) => {
  window.jockey.invoke('getPictureAction', { type }, (result: any) => {
    const { fileBase64 } = result.data
    return resolve(fileBase64)
  })
})

/**
 * 拨打电话
 * @param phoneNum 手机号
 * @returns
 */
export const phoneCall = (phoneNum: string) => new Promise((resolve) => {
  if (isInAppWebview()) {
    window.jockey.invoke('phoneCall', { phoneNum }, () => resolve(true))
  } else {
    window.location.href = `tel://${phoneNum}`
  }
})

// 设置webview Title
export const setApptitle = (title: any) => new Promise((resolve) => {
  if (isInAppWebview()) {
    window.jockey.invoke('setApptitle', { title }, () => resolve(true))
  } else {
    document.title = title
    resolve(true)
  }
})

/**
 * 打开文件
 * fileType: 文件类型
 * fileUrl:文件地址
 * title: 文件名或者titile
 * @param
 * @returns
 */
export const openFileView = (obj: OpenView) => new Promise((resolve) => {
  if (isInAppWebview()) {
    if (UserAgent.isAndroid()) {
      window.jockey.invoke('openFileView', obj, () => resolve(true))
    } else {
      window.open(obj.fileUrl)
    }
  } else {
    window.open(obj.fileUrl)
    resolve(true)
  }
})

// 读取用户文件
export const appUploadFileAction = (params: any) => new Promise((resolve) => {
  const obj = {
    isMultiple: false,
    allowedExtensions: ['pdf'],
    params
  }
  window.jockey.invoke('appUploadFileAction', obj, (result: any) => resolve(result.data))
})

/**
 * 关闭页面
 * @param {*} params
 */
export const appCloseWebView = () => {
  return new Promise(resolve => {
    if (isInAppWebview()) {
      window.jockey.invoke('appCloseWebView', {}, () => resolve(true))
    } else {
      resolve(true)
    }
  })
}

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { OpenType } from '@/enum'
import * as types from '@/store/modules/common/mutation-type'

export default function useOpenInfo () {
  const store = useStore()
  const route = useRoute()
  // const mutationTypes = ref('');
  const openCommon = computed(() => store.getters.openCommon)
  const openInfoLand = computed(() => store.getters.openInfoLand)
  const openInfoOthers = computed(() => store.getters.openInfoOthers)
  const { title, step } = route.meta

  // 开户状态 1：大陆 2、海外
  const openType = computed(() => openCommon.value.openType)

  // 缓存表开户数据
  const openInfo = computed(() => {
    if (openType.value === OpenType.MAINLAND) {
      return openInfoLand.value
    }
    return openInfoOthers.value
  })

  // 步骤状态
  const meta = computed(() => {
    if (openType.value === OpenType.MAINLAND) {
      return `land_${step}`
    }
    return `others_${step}`
  })

  const mutationTypes = computed(() => {
    if (openType.value === OpenType.MAINLAND) {
      return types.OPEN_INFO_LAND
    }
    return types.OPEN_INFO_OTHERS
  })

  const saveCommonOfVuex = (val: any) => {
    store.commit(types.OPEN_COMMON, val)
  }

  // 保存缓存表vuex
  const saveOpenInfoOfVuex = (val: any) => {
    store.commit(mutationTypes.value, val)
  }
  return {
    title,
    step,
    openType,
    openInfo,
    meta,
    mutationTypes,
    saveOpenInfoOfVuex,
    saveCommonOfVuex
  }
}

import { createRouter, RouteRecordRaw, createWebHashHistory, createWebHistory } from 'vue-router'
import agreementRouter from './arge'
import { RouterName } from '@/enum'
import OpenLayout from '@/views/layout/index.vue'

const _import = (file: string, path = 'index') => () => import(`../views/${file}/${path}.vue`); // eslint-disable-line

const routes: Array<RouteRecordRaw> = [
  {
    path: '/open-account',
    name: 'open-account',
    redirect: '/open-account/guide',
    component: OpenLayout,
    children: [
      {
        path: 'guide',
        name: RouterName.GUIDE,
        component: _import('guide')
      },
      {
        path: 'home',
        name: RouterName.STATUS,
        component: _import('home'),
        meta: { title: '开户' }
      },
      {
        path: 'reason-status',
        name: RouterName.REASON_STATUS,
        component: _import('status'),
        meta: { title: '开户' }
      },
      {
        path: 'id-type',
        name: RouterName.ID_TYPE,
        component: _import('id-type'),
        meta: { title: '上传证件' }
      },
      {
        path: 'id-card',
        name: RouterName.ID_CARD,
        component: _import('id-card'),
        meta: { title: '身份验证', step: 1 }
      },
      {
        path: 'id-card-info',
        name: RouterName.ID_CARD_INFO,
        component: _import('id-card-info'),
        meta: { title: '身份验证', step: 2 }
      },
      {
        path: 'contact-info',
        name: RouterName.CONTACT_INFO,
        component: _import('contact-info'),
        meta: { title: '联系信息', step: 3 }
      },
      {
        path: 'financial-info',
        name: RouterName.FINANCIAL_INFO,
        component: _import('financial-info'),
        meta: { title: '财务信息', step: 4 }
      },
      {
        path: 'tax-info',
        name: RouterName.TAX_INFO,
        component: _import('tax-info'),
        meta: { title: '税务信息', step: 5 }
      },
      {
        path: 'risk',
        name: RouterName.RISK,
        component: _import('risk'),
        meta: { title: '风险披露', step: 6 }
      },
      {
        path: 'other',
        name: RouterName.OTHER,
        component: _import('other'),
        meta: { title: '资料申报', step: 7 }
      },
      {
        path: 'pi',
        name: RouterName.PI,
        component: _import('pi'),
        meta: { title: '专业投资者认证', step: 8 }
      },
      {
        path: 'pi-risk',
        name: RouterName.PI_RISK,
        component: _import('pi-risk'),
        meta: { title: '专业投资者认证', step: 9 }
      },
      {
        path: 'pi-ar',
        name: RouterName.PI_AR,
        component: _import('pi-ar'),
        meta: { title: '专业投资者认证', step: 10 }
      },
      {
        path: 'statement',
        name: RouterName.STATEMENT,
        component: _import('statement'),
        meta: { title: '专业投资者认证', step: 11 }
      },
      {
        path: 'captcha',
        name: RouterName.CAPTCHA,
        component: _import('captcha'),
        meta: { title: '身份验证', step: 12 }
      },
      {
        path: 'confirm',
        name: RouterName.CONFIRM,
        component: _import('confirm'),
        meta: { title: '资料申报', step: 13 }
      },
      {
        path: 'signature',
        name: RouterName.SIGNATURE,
        component: _import('signature'),
        meta: { title: '客户签名' }
      },
      {
        path: 'transfer-step',
        name: RouterName.TRANSFER_STEP,
        component: _import('transfer-step'),
        meta: { title: '身份核实', step: 14 }
      }, {
        path: 'bind-card',
        name: RouterName.BIND_CARD,
        component: _import('bind-card'),
        meta: { title: '身份核实', step: 15 }
      },
      {
        path: 'transfer',
        name: RouterName.TRANSFER,
        component: _import('transfer'),
        meta: { title: '身份核实', step: 16 }
      },
      {
        path: 'transfer-pro',
        name: RouterName.TRANSFER_PRO,
        component: _import('transfer-pro'),
        meta: { title: '身份核实', step: 17 }
      },
      {
        path: 'reason',
        name: RouterName.REASON,
        component: _import('reason'),
        meta: { title: '驳回理由' }
      },
      {
        path: 'account-type',
        name: RouterName.ACCOUNT_TYPE,
        component: _import('account-type'),
        meta: { title: '账户类型', step: 18 }
      },
      {
        path: 'account-opening-statement',
        name: RouterName.ACCOUNT_OPENING_STATEMENT,
        component: _import('account-opening-statement'),
        meta: { title: '开户声明', step: 19 }
      },
      ...agreementRouter
    ]
  },
  {
    path: '/',
    redirect: '/open-account'
  }
]

const router = createRouter({
  scrollBehavior: ():any => ({ left: 0, top: 0 }),
  history: process.env.NODE_ENV === 'dev' ? createWebHashHistory() : createWebHistory(),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router

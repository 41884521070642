// 图片类型
export enum ImageType {
  ACC_IMAGE_TYPE_101 = 101, // 身份证正面-人像
  ACC_IMAGE_TYPE_102 = 102, // 身份证反面-国徽
  ACC_IMAGE_TYPE_103 = 103, // 香港永久居民证件正面
  ACC_IMAGE_TYPE_104 = 104, // 香港永久居民证件反面
  ACC_IMAGE_TYPE_105 = 105, // 香港非永久居民证件正面
  ACC_IMAGE_TYPE_106 = 106, // 香港非永久居民证件反面
  ACC_IMAGE_TYPE_107 = 107, // 护照正面
  ACC_IMAGE_TYPE_108 = 108, // 台湾居民证件正面
  ACC_IMAGE_TYPE_109 = 109, // 台湾居民证件反面
  ACC_IMAGE_TYPE_110 = 110, // 澳门居民证件正面
  ACC_IMAGE_TYPE_111 = 111, // 澳门居民证件反面
  ACC_IMAGE_TYPE_112 = 112, // 非中国大陆身份证件1
  ACC_IMAGE_TYPE_113 = 113, // 非中国大陆身份证件2
  ACC_IMAGE_TYPE_201 = 201, // 银行卡凭证
  ACC_IMAGE_TYPE_301 = 301, // 签名
  ACC_IMAGE_TYPE_401 = 401, // 开户同意信
  ACC_IMAGE_TYPE_501 = 501, // 地址证明
  ACC_IMAGE_TYPE_601 = 601, // 转账凭证
  ACC_IMAGE_TYPE_801 = 801, // 资产凭证
  ACC_IMAGE_TYPE_502 = 502 // 通讯地址证明
}

// 开户资料业务类型
export enum BusinessType {
  ACC_FILE_BUSINESS_TYPE_1 = 1, // 影像资料
  ACC_FILE_BUSINESS_TYPE_2 = 2, // 补充资料文件
  ACC_FILE_BUSINESS_TYPE_3 = 3, // AML文件
  ACC_FILE_BUSINESS_TYPE_4 = 4, // 编辑资料凭证
  ACC_FILE_BUSINESS_TYPE_5 = 5, // 见证人证明文件
  ACC_FILE_BUSINESS_TYPE_6 = 6, // 衍生品认知凭证
  ACC_FILE_BUSINESS_TYPE_7 = 7, // 尽职审查披露
  ACC_FILE_BUSINESS_TYPE_8 = 8, // PI认证
  ACC_FILE_BUSINESS_TYPE_9 = 9, // 转账凭证
  ACC_FILE_BUSINESS_TYPE_10 = 10, // 驳回文件
  ACC_FILE_BUSINESS_TYPE_11 = 11, // 开户同意信
  ACC_FILE_BUSINESS_TYPE_12 = 12 // 开户完成文件
}

// 开户类型
export enum OpenType {
  MAINLAND = 1,
  OVERSEAS = 2
}

// 开户证件类型
export enum IdKindType {
  MAINLAND_ID_CARD = 1, // 大陆身份证
  HK_PERMANENT_ID_CARD = 2, // 香港永久身份证
  HK_TEMPORARY_ID_CARD = 3, // 香港临时身份证
  TAIWAN_ID_CARD = 4, // 台湾居民身份证
  MACAU_ID_CARD = 5, // 澳门居民身份证
  OTHERS = 6 // 海外、护照
}

// 图片来源
export enum Source {
  H5 = 1,
  APP = 2,
  WEB = 3
}

// 开户录入类型
export enum InputWay {
  ONLINE = 1, // 线上
  OFFLINE = 2 // 线下
}

// 开户接入方式
export enum AccessWay {
  H5 = 1,
  APP = 2,
  WEB = 3
}

// 拍照、相册
export enum CameraOptions {
  CAMERA = 'camera',
  PHOTO = 'picture',
  FILE = 'file'
}

// 开户状态
export enum OpenStatus {
  REVIEW = 1, // 审核中
  DONE = 2, // 完成
  FAIL = 3, // 失败
  OPEN_REJECT = 4, // 驳回
  FAIL_BLACK = 5, // 黑名单
  NO_SUBMIT = 9, // 未提交
  SUPPLEMENT = 13, // 补充转账凭证
  ABOLISH = 14 // 作废
}

// 路由名称
export enum RouterName {
  GUIDE = 'guide',
  STATUS = 'home',
  REASON_STATUS = 'reason-status',
  ID_TYPE = 'id-type',
  ID_CARD = 'id-card',
  ID_CARD_INFO = 'id-card-info',
  CONTACT_INFO = 'contact-info',
  FINANCIAL_INFO = 'financial-info',
  TAX_INFO = 'tax-info',
  RISK = 'risk',
  OTHER = 'other',
  PI = 'pi',
  PI_RISK = 'pi-risk',
  PI_AR = 'pi-ar',
  STATEMENT = 'statement',
  CAPTCHA = 'captcha',
  CONFIRM = 'confirm',
  SIGNATURE = 'signature',
  TRANSFER_STEP = 'transfer-step',
  BIND_CARD = 'bind-card',
  TRANSFER = 'transfer',
  TRANSFER_PRO = 'transfer-pro',
  REASON = 'reason',
  ACCOUNT_TYPE = 'account-type',
  ACCOUNT_OPENING_STATEMENT = 'account-opening-statement'
}
// PI资产准则（个人）
export enum PiAssetsCriterionCode {
  PI_ASSETS_CRITERION_CODE_1 = '1',
  PI_ASSETS_CRITERION_CODE_2 = '2',
  PI_ASSETS_CRITERION_CODE_3 = '3',
  PI_ASSETS_CRITERION_CODE_4 = '4'
}
// PI资产证明材料（个人）
export enum PiAssetsFileCode {
  PI_ASSETS_FILE_CODE_1 = '1',
  PI_ASSETS_FILE_CODE_2 = '2'
}
// 上传文件类型
export enum PiApproveFileDesc {
  PI_APPROVE_FILE_DESC_1 = '1',
  PI_APPROVE_FILE_DESC_2 = '2',
  PI_APPROVE_FILE_DESC_3 = '3',
  PI_APPROVE_FILE_DESC_4 = '4',
  PI_APPROVE_FILE_DESC_5 = '5'
}

import { ref, computed } from 'vue'

export const useLoading = () => {
  const loadingIndex = ref(0)
  const isLoading = computed(() => !(loadingIndex.value <= 0))
  const enterLoading = () => {
    loadingIndex.value += 1
  }
  const quitLoading = () => {
    if (loadingIndex.value > 0) {
      loadingIndex.value -= 1
    } else {
      console.log('loading 为0')
    }
  }
  const loadingWrapper = <T>(promise: Promise<T>) => {
    return new Promise<void>((resolve, reject) => {
      enterLoading()
      promise.finally(() => {
        quitLoading()
        resolve()
      })
    })
  }
  return {
    isLoading,
    enterLoading,
    quitLoading,
    loadingWrapper
  }
}

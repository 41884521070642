// 开户类型
export const OPEN_TYPE = 'OPEN_TYPE'

// 大陆开户缓存信息
export const OPEN_INFO_LAND = 'OPEN_INFO_LAND'

// 大陆开户缓存图片
export const OPEN_IMG_LAND = 'OPEN_IMG_LAND'

// 海外开户缓存信息
export const OPEN_INFO_OTHERS = 'OPEN_INFO_OTHERS'

// 海外开户缓存图片
export const OPEN_IMG_OTHERS = 'OPEN_IMG_OTHERS'

// 开户公共类型
export const OPEN_COMMON = 'OPEN_COMMON'
// loading
export const SET_LOAIDING = 'SET_LOAIDING'

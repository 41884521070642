const redirectUrl = () => {
  const { search } = window.location
  let url
  const href = encodeURIComponent(window.location.href)
  if (search) {
    url = `${search}&redirect_url=${href}`
  } else {
    url = `?redirect_url=${href}`
  }
  window.location.href = process.env.VUE_APP_H5_USER_LOGIN_URL + url
}

export default redirectUrl

<template>
  <div class="main">
    <!-- 进度条 -->
    <progress-bar v-if="showPro" :type="1" :current="current" class="bar"></progress-bar>

    <div class="container">
      <van-form @submit="handleNext" @failed="onFail" :scroll-to-error="true">
        <!-- 默认插槽 -->
        <slot></slot>
        <!-- 下一步按钮 -->
        <div :class="`footer-btns ${isFixed ? 'fixed' : ''}`" v-show="nextBtnShow">
          <van-button plain size="small" class="btn-preview" v-show="preBtnShow" @click="handlePre">上一步</van-button>
          <van-button
            :disabled="isCannext"
            :class="`btn-next ${isCannext ? 'disabled' : ''}`"
            size="small"
            native-type="submit"
            type="primary"
            >{{ btnText }}</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import scrollView from '@/utils/scroll-view'
import useOpenInfo from '@/hooks/openInfo'

export default defineComponent({
  name: 'Layout',
  props: {
    nextBtnShow: {
      type: Boolean,
      default: true
    },
    preBtnShow: {
      type: Boolean,
      default: true
    },
    showPro: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    current: {
      type: Number,
      default: 0
    },
    btnText: {
      type: String,
      default: '下一步'
    },
    form: {
      type: Object,
      default: () => ({})
    },
    prePathName: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // const isClicked = ref(false);
    const { saveOpenInfoOfVuex } = useOpenInfo()
    const onFail = val => {
      console.log(val)
      scrollView()
    }
    const handleNext = val => {
      console.log(val)
      // 防止多次点击
      // if (isClicked.value) return;
      // isClicked.value = true;
      emit('handleNext')
    }
    const router = useRouter()
    const route = useRoute()
    const handlePre = () => {
      // router.go(-1);
      router.push({ name: props.prePathName })
      // emit('handlePre');
      saveOpenInfoOfVuex({ info: props.form })
    }
    const isCannext = computed(() => props.isDisabled)
    const isFixed = computed(
      () =>
        route.name === 'online-cn' ||
        route.name === 'online-hk' ||
        route.name === 'captcha' ||
        route.name === 'transfer-step'
    )
    return {
      handleNext,
      route,
      isCannext,
      handlePre,
      onFail,
      isFixed
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  // padding: 0 15px 90px;
  padding: 0 15px;
}
.disabled {
  background-color: #363d56;
  opacity: 0.9;
  color: #161617;
}
.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.footer-btns {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  display: flex;
  padding: 25px 10px;
  background-color: $bg-color;
  .btn-preview {
    width: 120px;
    color: #c8cfd9;
    border: 1px solid #959db7;
    border-radius: 8px 0px 8px 0px;
    margin-right: 15px;
  }
  .btn-next {
    flex: 1;
    color: #101010;
  }
}
//   .progress-bar+.main {
//     padding-top: 115px;
//   }
</style>

<template>
  <span class="copyBtn" :data-value="value">
    <slot></slot>
  </span>
</template>

<script type="ts">
import { defineComponent, onMounted } from 'vue'
import ClipboardJS from 'clipboard'
import { Message } from '@/components/Message'

export default defineComponent({
  name: 'CopyBoard',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup () {
    const initCopy = () => {
      const instance = new ClipboardJS('.copyBtn', {
        text (trigger) {
          return trigger.getAttribute('data-value') || ''
        }
      })
      instance.on('success', () => {
        Message({ direction: 'vertical', type: 'success', text: '复制成功' })
      })
      instance.on('error', () => {
        Message({ direction: 'vertical', text: '该浏览器不支持自动复制!' })
      })
    }
    onMounted(() => {
      initCopy()
    })
    return {}
  }
})
</script>

<style scoped lang="scss"></style>

import { createStore } from 'vuex'
import common from './modules/common'

export default createStore({
  state: {
    openInfo: {
    }
  },
  mutations: {
    setOpenInfo (state, payload) {
      state.openInfo = { ...state.openInfo, ...payload }
    }
  },
  actions: {
  },
  modules: {
    common
  }
})

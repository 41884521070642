import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ae731916")
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "footer" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["confirm", { fade: $setup.fade }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["wrapper", { fade: $setup.fade }])
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1),
      _createElementVNode("div", {
        class: "body",
        innerHTML: $props.content
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        ($props.cancelText)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn-cancel",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.cancel && $setup.cancel(...args)))
            }, _toDisplayString($props.cancelText), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "btn-confirm",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.submit && $setup.submit(...args)))
        }, _toDisplayString($props.confirmText), 1)
      ])
    ], 2)
  ], 2))
}
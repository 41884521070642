import * as types from './mutation-type'

const state = {
  openType: '',
  openInfoLand: {},
  openInfoOthers: {},
  openCommon: {},
  globalLoading: true
}

const getters = {
  openType: (state: { openType: number }) => state.openType,
  openInfoLand: (state: any) => state.openInfoLand,
  openInfoOthers: (state: any) => state.openInfoOthers,
  openCommon: (state: any) => state.openCommon,
  getLoad: (state: any) => state.globalLoading
}

const mutations = {
  [types.OPEN_TYPE] (state: any, payload: { openType: number }) {
    state.openType = payload.openType
  },
  [types.OPEN_INFO_LAND] (state: any, payload: any) {
    const { openInfoLand } = state
    state.openInfoLand = { ...openInfoLand, ...payload.info }
  },
  [types.OPEN_INFO_OTHERS] (state: any, payload: any) {
    const { openInfoOthers } = state
    state.openInfoOthers = { ...openInfoOthers, ...payload.info }
  },
  [types.OPEN_COMMON] (state: any, payload: any) {
    const { openCommon } = state
    state.openCommon = { ...openCommon, ...payload.info }
  },
  [types.SET_LOAIDING] (state: any, payload: boolean) {
    state.globalLoading = payload
  }
}

export default {
  state,
  getters,
  mutations
}

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, Transition as _Transition, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9dbac3ba")
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "message2" }
const _hoisted_4 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_Transition, { name: "down" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "message",
          style: _normalizeStyle(_ctx.style[_ctx.type])
        }, [
          _createElementVNode("img", {
            class: "iconfont",
            src: _ctx.style[_ctx.type].icon
          }, null, 8, _hoisted_1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
        ], 4)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.visible && _ctx.horizontal]
    ]),
    _withDirectives(_createVNode(_Transition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.style2[_ctx.type].icon,
            alt: ""
          }, null, 8, _hoisted_4),
          _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.visible && !_ctx.horizontal]
    ])
  ], 64))
}
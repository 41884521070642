
import { reactive, defineComponent, watch, computed } from 'vue'
export default defineComponent({
  name: 'CustomPicker',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:value', 'confirm', 'showPickerClick'],
  setup (props: any, { emit }) {
    const state: any = reactive({
      showSelectPicker: false,
      value: null,
      field: null
    })

    const pickerColumns = computed(() => {
      return props.columns.map((v: any) => ({
        name: v.text || v.name,
        value: v.value || v.name
      }))
    })

    const selectConfirm = (event: any) => {
      state.value = event.value
      state.field = event.name
      // console.log(event)
      state.showSelectPicker = false
      emit('update:value', event.value)
      emit('confirm', event)
    }

    const showSelectPickerToggle = () => {
      state.showSelectPicker = !state.showSelectPicker
      emit('showPickerClick', state.showSelectPicker)
    }

    const findIdx = () => {
      const idx = pickerColumns.value.findIndex((item: any) => item.value === state.value)
      return idx === -1 ? 0 : idx
    }

    watch(() => [props.value, props.columns], ([val]) => {
      state.value = val
      state.field = pickerColumns.value.find((item: any) => item.value === val)?.name || ''
    }, { immediate: true })

    return {
      state,
      pickerColumns,
      selectConfirm,
      showSelectPickerToggle,
      findIdx
    }
  }
})

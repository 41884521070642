<template>
  <router-view />
  <van-overlay :show="loading">
    <div class="loading">
      <img
        src="@/assets/images/loading_icon.png"
        alt=""
        srcset="@/assets/images/loading_icon@2x.png 2x @/assets/images/loading_icon@3x.png 3x"
      />
      <div style="color: rgba(255, 255, 255, 0.9)">加载中</div>
    </div>
  </van-overlay>
  <float-button></float-button>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useGlobalQueryLoading, useGlobalMutationLoading } from '@vue/apollo-composable'
import { useStore } from 'vuex'

export default defineComponent({
  setup () {
    const store = useStore()
    const loading = computed(() => store.getters.getLoad && useGlobalQueryLoading && useGlobalMutationLoading)
    return {
      loading
    }
  }
})
</script>

<style scoped lang="scss">
@mixin pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
.loading {
  width: 136px;
  height: 136px;
  background: #2a3148;
  border-radius: 8px;
  @include pos-center();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img {
  width: 40px;
  margin-bottom: 16px;
  transform: rotate(360deg);
  animation: rotation 1.4s linear infinite;
  -moz-animation: rotation 1.4s linear infinite;
  -webkit-animation: rotation 1.4s linear infinite;
  -o-animation: rotation 1.4s linear infinite;
}
</style>


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProgressBar',
  props: {
    type: {
      // 类型
      type: Number,
      default: 0
    },
    skin: {
      // 皮肤
      type: String,
      default: ''
    },
    current: {
      // 当前索引
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => [
        { text: '身份验证' },
        { text: '基本资料' },
        { text: '风险披露' },
        { text: '身份核实' }
      ]
    }
  }
})

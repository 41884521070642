import qs from 'qs'
import router from './index'
import * as storage from '@/utils/storage'
import { isInAppWebview, getUserInfo, setApptitle } from '@/utils/native-app'
import { base64Decrypt } from '@/utils/crypto'
import redirectUrl from '@/utils/redirect-url'

const { search } = window.location
const { userInfo = '' }: any = qs.parse(search, { ignoreQueryPrefix: true })
if (userInfo) {
  const info = JSON.parse(base64Decrypt(userInfo))
  storage.set('userInfo', info)
}

router.beforeEach((to, from, next) => {
  setApptitle(to.meta.title || 'OpenAccount')
  if (isInAppWebview()) {
    getUserInfo().then(() => {
      next()
    })
  } else {
    const { sessionId }: any = storage.get('userInfo') || {}
    if (sessionId) {
      next()
    } else {
      redirectUrl()
    }
  }
})

router.afterEach((to, from) => {
  // console.log(to, from)
  // 一些路由后置条件判断
})


import { computed, defineComponent, onMounted, ref } from 'vue'
type StyleType = {
  [propName: string]: {
    [propName: string]: string
  }
}
export default defineComponent({
  name: 'Message',
  props: {
    type: {
      type: String,
      default: 'warn'
    },
    text: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  setup (props: any) {
    const style: StyleType = {
      warn: {
        icon: require('@/assets/images/icons/warning-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      error: {
        icon: require('@/assets/images/icons/error-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      success: {
        icon: require('@/assets/images/icons/success-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      }
    }
    const style2: StyleType = {
      warn: {
        icon: require('@/assets/images/icons/warning-toast.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      },
      success: {
        icon: require('@/assets/images/icons/icon_dui.svg'),
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgb(42, 49, 72)',
        borderColor: '#2A3148'
      }
    }
    const horizontal = computed(() => props.direction === 'horizontal')
    // 控制元素显示隐藏
    const visible = ref(false)
    onMounted(() => {
      visible.value = true
    })
    return { style, style2, horizontal, visible }
  }
})

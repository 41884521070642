import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-picker-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_field, _mergeProps({
      readonly: "",
      class: "custom-van-field"
    }, _ctx.$attrs, {
      modelValue: _ctx.state.field,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.field = $event)),
      "right-icon": "arrow-down",
      onClick: _ctx.showSelectPickerToggle
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "right-icon")
      ]),
      _: 3
    }, 16, ["modelValue", "onClick"]),
    _createVNode(_component_van_popup, {
      round: "",
      show: _ctx.state.showSelectPicker,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.showSelectPicker = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          title: "请选择",
          "default-index": _ctx.findIdx(),
          columns: _ctx.pickerColumns,
          "columns-field-names": { text: 'name', value: 'value' },
          onConfirm: _ctx.selectConfirm,
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.showSelectPicker = false))
        }, null, 8, ["default-index", "columns", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2c510cf4")
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.show,
    onClickOverlay: _ctx.clickOverlay,
    position: "bottom",
    style: _normalizeStyle({ height: _ctx.height })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", { innerHTML: _ctx.content }, null, 8, _hoisted_3),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
          class: _normalizeClass({ scroll: _ctx.title === '什么是投资组合？' })
        }, _toDisplayString(_ctx.confirmText), 3)
      ])
    ]),
    _: 1
  }, 8, ["show", "onClickOverlay", "style"]))
}
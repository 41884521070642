import { createApp, h, provide } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import Vant, { ConfigProvider } from 'vant'
import * as Sentry from '@sentry/vue'
import VConsole from 'vconsole'
import 'vant/lib/index.css'
import i18n from './i18n'
import apolloClient from './apollo-client'
import App from './App.vue'
import router from './router'
import store from './store'
import UI from './components/register'
import './router/permission'

import './assets/rem'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont.scss'

if (process.env.VUE_APP_CONSOLE === 'show') {
  const vConsole = new VConsole({})
  console.log('vConsole', vConsole)
}

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://65dec86972d632aa24c15bde723bcd34@sentry.fwdev.top/6',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(Vant)
  .use(UI)
  .use(ConfigProvider)
  .mount('#app')

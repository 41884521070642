<template>
  <div
    class="float_button"
    ref="floatButton"
    @click="openDialog"
    :style="{
      width: itemWidth + 'px',
      height: itemHeight + 'px',
      left: left + 'px',
      top: top + 'px'
    }"
  >
    <div class="float_info">
      <i class="iconfont icon-kefu-ermai"></i>
    </div>
    <div>人工客服</div>
  </div>
  <!-- <custom-dialog v-model:show="show"  @close="close" @confirm="confirm"/> -->
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'
import { phoneCall } from '@/utils/native-app'
import { Confirm } from '../confirm'

export default defineComponent({
  name: 'FloatButton',
  setup () {
    const clientWidth = ref('0')
    const clientHeight = ref('0')
    const left = ref(0)
    const top = ref(0)
    const itemWidth = ref(80)
    const itemHeight = ref(90)
    const gapWidth = ref(0) // 距离左右两边距离
    const coefficientHeight = ref(0.6) // 从上到下距离比例
    // const type = navigator.userAgent;
    // clientWidth.value = document.documentElement.clientWidth;
    // clientHeight.value = document.documentElement.clientHeight;
    clientWidth.value = window.innerWidth
    clientHeight.value = window.innerHeight

    left.value = clientWidth.value - itemWidth.value - gapWidth.value
    top.value = clientHeight.value * coefficientHeight.value
    const floatButton = ref(null)
    onMounted(() => {
      floatButton.value.addEventListener('touchstart', () => {
        // e.preventDefault();
        floatButton.value.style.transition = 'none'
      })

      floatButton.value.addEventListener('touchmove', e => {
        e.preventDefault()
        if (e.targetTouches.length === 1) {
          const touch = e.targetTouches[0] || e.changeTouches[0]
          left.value = touch.clientX - 40
          top.value = touch.clientY < 0 ? 0 : touch.clientY
          if (touch.clientY >= clientHeight.value - itemWidth.value) {
            top.value = touch.clientY - itemWidth.value
          }
        }
      })

      floatButton.value.addEventListener('touchend', () => {
        // e.preventDefault();
        floatButton.value.style.transition = 'all 0.3s'
        if (left.value > clientWidth.value / 2) {
          left.value = clientWidth.value - itemWidth.value
        } else {
          left.value = 0
        }
        if (top.value >= clientHeight.value - itemWidth.value) {
          top.value = clientHeight.value - itemWidth.value
        }
      })
    })
    const show = ref(false)
    const openDialog = () => {
      // show.value = true
      Confirm()
        .then(id => {
          phoneCall(id)
        })
        .catch(() => {
          //
        })
    }
    // const close = val => {
    //   show.value = val
    // }
    // const confirm = val => {
    //   // 拨打电话
    //   phoneCall('852-21533838').then(res => {
    //     show.value = val
    //   })
    // }
    watch(left, () => {
      //
    })

    return {
      clientWidth,
      clientHeight,
      left,
      top,
      itemWidth,
      itemHeight,
      gapWidth,
      coefficientHeight,
      floatButton,
      show,
      openDialog
    }
  }
})
</script>

<style lang="scss">
.float_button {
  transition: all 0.3s;
  position: fixed;
  bottom: 436px;
  right: 0;
  // margin: 5px 10px;
  @include flex-box($main: column, $row: center);
  font-size: 12px;
  z-index: 999;
  background-color: #212639;
  opacity: 0.9;
  border-radius: 8px;
  padding: 5px 0;
  .float_info {
    width: 48px;
    height: 48px;
    @include flex-box($row: center);
    // z-index: 999;
    background: $theme;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 5px;
    .icon-kefu-ermai {
      font-size: 20px;
      color: #fff;
    }
  }
}
.dialog-content {
  text-align: center;
  padding: 15px 0 20px;
  line-height: 2;
}
.footer-btns {
  display: flex;
  justify-content: space-around;
  // padding-bottom: 40px;
  .btn-cancel {
    color: #dcdfe9;
  }
  .btn-cancel,
  .btn-confirm {
    width: 120px;
    height: 35px;
  }
}
</style>

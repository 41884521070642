<template>
  <div class="confirm" :class="{ fade }" @click="cancel">
    <div class="wrapper" :class="{ fade }">
      <div class="body">
        <div class="call" @click="submit('+852 2153 3838')">
          <img
            src="@/assets/images/icons/icon_phone.png"
            srcset="@/assets/images/icons/icon_phone@2x.png 2x, @/assets/images/icons/icon_phone@3x.png 3x"
            alt=""
          />
          <span class="area">大陆客服</span>
          <span class="phone">+852 2153 3838</span>
        </div>
        <div class="call" @click="submit('2153 3838')">
          <img
            src="@/assets/images/icons/icon_phone.png"
            srcset="@/assets/images/icons/icon_phone@2x.png 2x, @/assets/images/icons/icon_phone@3x.png 3x"
            alt=""
          />
          <span class="area">香港客服</span>
          <span class="phone">2153 3838</span>
        </div>
      </div>
      <div class="footer">
        <p>服务时间：星期一到星期五（公众假期除外)</p>
        <p>上午9:00-12:00 下午13:00-18:00</p>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
export default {
  name: 'Confirm',
  props: {
    text: {
      type: String,
      default: ''
    },
    onConfirm: {
      type: Function
    },
    onCancel: {
      type: Function
    }
  },
  setup (props) {
    // 对话框默认隐藏
    const fade = ref(false)
    // 组件渲染完毕后
    onMounted(() => {
      // 过渡效果需要在元素创建完毕后延时一会加上才会触发
      setTimeout(() => {
        fade.value = true
      }, 0)
    })
    // 取消
    const cancel = () => {
      props.onCancel()
    }
    // 确认
    const submit = number => {
      // 点击确认后，关闭对话框
      props.onConfirm(number)
    }
    return { cancel, submit, fade }
  }
}
</script>
<style lang="scss" scoped>
/* 居中 动画 */
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.confirm .wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  background-color: #2a3148;
  border-radius: 12px;
}
.confirm .wrapper .body {
  box-sizing: border-box;
  padding: 28px 0 0px;
  .call {
    padding: 14px 40px;
    border-bottom: 1px solid rgba(191, 199, 222, 0.1);
    img {
      width: 16px;
      vertical-align: text-top;
    }
    .area {
      margin-left: 6px;
      margin-right: 10px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .phone {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ffb400;
    }
  }
}
.footer {
  @include flex-box(center, center, column);
  padding: 10px 42px;
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #81879c;
    &:last-child {
      margin-top: 10px;
    }
  }
}
</style>

const _import = (file: string, path = 'index') => () => import(`../views/${file}/${path}.vue`); // eslint-disable-line

const agreementRouter = [
  {
    path: 'personal-info',
    name: 'personal-info',
    component: _import('personal-info'),
    meta: { title: '税务信息' }
  },
  {
    path: 'fatca',
    name: 'fatca',
    component: _import('fatca'),
    meta: { title: '税务信息' }
  },
  {
    path: 'accinfo-statement',
    name: 'accinfo-statement',
    component: _import('accinfo-statement'),
    meta: { title: '税务信息' }
  },
  {
    path: 'client-statement',
    name: 'client-statement',
    component: _import('client-statement'),
    meta: { title: '客户声明' }
  },
  {
    path: 'risk-statement',
    name: 'risk-statement',
    component: _import('risk-statement'),
    meta: { title: '风险披露声明' }
  }
]

export default agreementRouter

import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from } from '@apollo/client/core'
import { Dialog } from 'vant'
import { onError } from '@apollo/client/link/error'
import signWrap from '@/apollo-client/sign'
import redirectUrl from '@/utils/redirect-url'
import { isInAppWebview } from '@/utils/native-app'
import * as types from '@/store/modules/common/mutation-type'
import store from '@/store/index'
import { useLoading } from '@/hooks/loading'
import { Message } from '@/components/Message'
const { isLoading, enterLoading, quitLoading } = useLoading()

const cache = new InMemoryCache()
const BASE_URL = process.env.VUE_APP_BASE_API

const httpLink = new HttpLink({
  uri: BASE_URL
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    quitLoading()
    store.commit(types.SET_LOAIDING, isLoading)
    graphQLErrors.forEach((item: any) => {
      const isApp = isInAppWebview()
      switch (item.err) {
        case 542:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试(542)' })
          break
        // case 543:
        //   Message('请求异常，请稍后重试(543)')
        //   break
        case 544:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试(544)' })
          break
        case 546:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试(546)' })
          break
        case 547:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试(547)' })
          break
        case 543:
        case 545:
          if (isApp) {
            Dialog.alert({
              title: '温馨提示',
              message: 'Token异常，请退出APP重新登陆！'
            })
          } else {
            Message({ direction: 'vertical', text: 'session失效，请重新登陆！' })
            redirectUrl()
          }
          break
        default:
          Message({ direction: 'vertical', text: '请求异常，请稍后重试！' })
          break
      }
    })
  }
})

const additiveLink = new ApolloLink((operation, forward) => {
  enterLoading()
  store.commit(types.SET_LOAIDING, isLoading)
  operation.setContext(() => ({
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      ...signWrap(operation)
    }
  }))

  return forward(operation).map(data => {
    quitLoading()
    store.commit(types.SET_LOAIDING, isLoading)
    // console.log(data.data)
    return data
  })
})

const apolloClient = new ApolloClient({
  cache,
  link: from([additiveLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export default apolloClient

import { createI18n } from 'vue-i18n'
import cn from './lang/zh-cn'
import hk from './lang/zh-hk'

const messages = {
  'zh-cn': cn,
  'zh-hk': hk
}

const i18n = createI18n({
  // something vue-i18n options here
  locale: 'zh-cn',
  messages
})

export default i18n


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '税务信息'
    },
    height: {
      type: String,
      default: '70%'
    },
    // content: {
    //   type: Array,
    //   default: () => [
    //     '请填写您的纳税所在国家（地区）和对应的税务编号（TIN），对于在香港/中国内地的纳税居民，税务编号是您的香港/中国内地身份证号'
    //   ]
    content: {
      type: String,
      default:
        '<p>请填写您的纳税所在国家（地区）和对应的税务编号（TIN），对于在香港/中国内地的纳税居民，税务编号是您的香港/中国内地身份证号</p>'
    },
    confirmText: {
      type: String,
      default: '知道了'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const closePopup = () => {
      emit('closePopup', false)
    }
    const clickOverlay = () => {
      emit('clickOverlay')
    }
    return {
      closePopup,
      clickOverlay
    }
  }
})
